import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTooltip @placement='bottom' @arrow={{true}} @color='light'>\n  <:tooltipContent>\n    <div local-class='tooltip-content'>\n      <AkStack @spacing='0.5' @alignItems='center'>\n        <AkIcon @iconName='info' @color='textSecondary' @size='small' />\n\n        <AkTypography @color='textSecondary'>\n          {{t 'storeknox.info'}}\n        </AkTypography>\n      </AkStack>\n\n      <AkTypography class='mt-1'>\n        {{@indicatorInfo}}\n      </AkTypography>\n    </div>\n  </:tooltipContent>\n\n  <:default>\n    {{#let (component @svgComponent) as |SvgComponent|}}\n      <SvgComponent />\n    {{/let}}\n  </:default>\n</AkTooltip>", {"contents":"<AkTooltip @placement='bottom' @arrow={{true}} @color='light'>\n  <:tooltipContent>\n    <div local-class='tooltip-content'>\n      <AkStack @spacing='0.5' @alignItems='center'>\n        <AkIcon @iconName='info' @color='textSecondary' @size='small' />\n\n        <AkTypography @color='textSecondary'>\n          {{t 'storeknox.info'}}\n        </AkTypography>\n      </AkStack>\n\n      <AkTypography class='mt-1'>\n        {{@indicatorInfo}}\n      </AkTypography>\n    </div>\n  </:tooltipContent>\n\n  <:default>\n    {{#let (component @svgComponent) as |SvgComponent|}}\n      <SvgComponent />\n    {{/let}}\n  </:default>\n</AkTooltip>","moduleName":"irene/components/storeknox/indicator/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/indicator/index.hbs"}});
import Component from '@glimmer/component';

export interface StoreknoxIndicatorSignature {
  Element: HTMLElement;
  Args: {
    indicatorInfo: string;
    svgComponent:
      | 'ak-svg/sm-indicator'
      | 'ak-svg/info-indicator'
      | 'ak-svg/vapt-indicator'
      | 'ak-svg/disabled-vapt-indicator';
  };
}

export default class StoreknoxIndicatorComponent extends Component<StoreknoxIndicatorSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Indicator': typeof StoreknoxIndicatorComponent;
  }
}
