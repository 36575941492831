import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @alignItems='center' local-class='empty-container'>\n\n  <AkSvg::NoPendingItems />\n\n  <AkTypography @variant='h5' @gutterBottom={{true}} local-class='header'>\n    {{t 'storeknox.noPendingItems'}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text'>\n    {{t 'storeknox.noPendingItemsDescription'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @direction='column' @alignItems='center' local-class='empty-container'>\n\n  <AkSvg::NoPendingItems />\n\n  <AkTypography @variant='h5' @gutterBottom={{true}} local-class='header'>\n    {{t 'storeknox.noPendingItems'}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text'>\n    {{t 'storeknox.noPendingItemsDescription'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/storeknox/inventory/pending-review/empty/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory/pending-review/empty/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxInventoryPendingReviewEmptyComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Inventory::PendingReview::Empty': typeof StoreknoxInventoryPendingReviewEmptyComponent;
  }
}
