export default {
  "ak-icon": "_ak-icon_1ahf46",
  "ak-icon-outlined": "_ak-icon-outlined_1ahf46",
  "ak-icon-round": "_ak-icon-round_1ahf46",
  "ak-icon-folder": "_ak-icon-folder_1ahf46",
  "ak-icon-graphic-eq": "_ak-icon-graphic-eq_1ahf46",
  "ak-icon-people": "_ak-icon-people_1ahf46",
  "ak-icon-inventory-2": "_ak-icon-inventory-2_1ahf46",
  "ak-icon-account-box": "_ak-icon-account-box_1ahf46",
  "ak-icon-account-balance": "_ak-icon-account-balance_1ahf46",
  "ak-icon-credit-card": "_ak-icon-credit-card_1ahf46",
  "ak-icon-groups-2": "_ak-icon-groups-2_1ahf46",
  "ak-icon-groups": "_ak-icon-groups_1ahf46",
  "ak-icon-arrow-left": "_ak-icon-arrow-left_1ahf46",
  "ak-icon-arrow-right": "_ak-icon-arrow-right_1ahf46",
  "ak-icon-west": "_ak-icon-west_1ahf46",
  "ak-icon-warning": "_ak-icon-warning_1ahf46",
  "ak-icon-warning-amber": "_ak-icon-warning-amber_1ahf46",
  "ak-icon-arrow-back": "_ak-icon-arrow-back_1ahf46",
  "ak-icon-arrow-drop-down": "_ak-icon-arrow-drop-down_1ahf46",
  "ak-icon-arrow-drop-up": "_ak-icon-arrow-drop-up_1ahf46",
  "ak-icon-search": "_ak-icon-search_1ahf46",
  "ak-icon-check-box-outline-blank": "_ak-icon-check-box-outline-blank_1ahf46",
  "ak-icon-check-box": "_ak-icon-check-box_1ahf46",
  "ak-icon-indeterminate-check-box": "_ak-icon-indeterminate-check-box_1ahf46",
  "ak-icon-radio-button-unchecked": "_ak-icon-radio-button-unchecked_1ahf46",
  "ak-icon-radio-button-checked": "_ak-icon-radio-button-checked_1ahf46",
  "ak-icon-check-circle": "_ak-icon-check-circle_1ahf46",
  "ak-icon-cancel": "_ak-icon-cancel_1ahf46",
  "ak-icon-close": "_ak-icon-close_1ahf46",
  "ak-icon-done": "_ak-icon-done_1ahf46",
  "ak-icon-refresh": "_ak-icon-refresh_1ahf46",
  "ak-icon-delete": "_ak-icon-delete_1ahf46",
  "ak-icon-more-vert": "_ak-icon-more-vert_1ahf46",
  "ak-icon-note-add": "_ak-icon-note-add_1ahf46",
  "ak-icon-group-add": "_ak-icon-group-add_1ahf46",
  "ak-icon-integration-instructions": "_ak-icon-integration-instructions_1ahf46",
  "ak-icon-verified": "_ak-icon-verified_1ahf46",
  "ak-icon-subdirectory-arrow-right": "_ak-icon-subdirectory-arrow-right_1ahf46",
  "ak-icon-download": "_ak-icon-download_1ahf46",
  "ak-icon-security": "_ak-icon-security_1ahf46",
  "ak-icon-settings": "_ak-icon-settings_1ahf46",
  "ak-icon-settings-applications": "_ak-icon-settings-applications_1ahf46",
  "ak-icon-person-remove": "_ak-icon-person-remove_1ahf46",
  "ak-icon-remove": "_ak-icon-remove_1ahf46",
  "ak-icon-edit": "_ak-icon-edit_1ahf46",
  "ak-icon-file-upload": "_ak-icon-file-upload_1ahf46",
  "ak-icon-event": "_ak-icon-event_1ahf46",
  "ak-icon-add": "_ak-icon-add_1ahf46",
  "ak-icon-assignment": "_ak-icon-assignment_1ahf46",
  "ak-icon-notifications": "_ak-icon-notifications_1ahf46",
  "ak-icon-dynamic-feed": "_ak-icon-dynamic-feed_1ahf46",
  "ak-icon-cloud-upload": "_ak-icon-cloud-upload_1ahf46",
  "ak-icon-import-contacts": "_ak-icon-import-contacts_1ahf46",
  "ak-icon-support": "_ak-icon-support_1ahf46",
  "ak-icon-account-circle": "_ak-icon-account-circle_1ahf46",
  "ak-icon-mail": "_ak-icon-mail_1ahf46",
  "ak-icon-logout": "_ak-icon-logout_1ahf46",
  "ak-icon-open-in-new": "_ak-icon-open-in-new_1ahf46",
  "ak-icon-view-list": "_ak-icon-view-list_1ahf46",
  "ak-icon-file-download": "_ak-icon-file-download_1ahf46",
  "ak-icon-content-copy": "_ak-icon-content-copy_1ahf46",
  "ak-icon-receipt-long": "_ak-icon-receipt-long_1ahf46",
  "ak-icon-history-toggle-off": "_ak-icon-history-toggle-off_1ahf46",
  "ak-icon-trending-up": "_ak-icon-trending-up_1ahf46",
  "ak-icon-view-stream": "_ak-icon-view-stream_1ahf46",
  "ak-icon-trending-down": "_ak-icon-trending-down_1ahf46",
  "ak-icon-info": "_ak-icon-info_1ahf46",
  "ak-icon-block": "_ak-icon-block_1ahf46",
  "ak-icon-help": "_ak-icon-help_1ahf46",
  "ak-icon-description": "_ak-icon-description_1ahf46",
  "ak-icon-compare-arrows": "_ak-icon-compare-arrows_1ahf46",
  "ak-icon-format-list-bulleted": "_ak-icon-format-list-bulleted_1ahf46",
  "ak-icon-play-arrow": "_ak-icon-play-arrow_1ahf46",
  "ak-icon-stop": "_ak-icon-stop_1ahf46",
  "ak-icon-build": "_ak-icon-build_1ahf46",
  "ak-icon-person-off": "_ak-icon-person-off_1ahf46",
  "ak-icon-person-add": "_ak-icon-person-add_1ahf46",
  "ak-icon-person": "_ak-icon-person_1ahf46",
  "ak-icon-error": "_ak-icon-error_1ahf46",
  "ak-icon-undo": "_ak-icon-undo_1ahf46",
  "ak-icon-arrow-down": "_ak-icon-arrow-down_1ahf46",
  "ak-icon-keyboard-backspace": "_ak-icon-keyboard-backspace_1ahf46",
  "ak-icon-south": "_ak-icon-south_1ahf46",
  "ak-icon-east": "_ak-icon-east_1ahf46",
  "ak-icon-menu": "_ak-icon-menu_1ahf46",
  "ak-icon-sync-alt": "_ak-icon-sync-alt_1ahf46",
  "ak-icon-home": "_ak-icon-home_1ahf46",
  "ak-icon-report-problem": "_ak-icon-report-problem_1ahf46",
  "ak-icon-android": "_ak-icon-android_1ahf46",
  "ak-icon-apple": "_ak-icon-apple_1ahf46",
  "ak-icon-windows": "_ak-icon-windows_1ahf46",
  "ak-icon-mobile": "_ak-icon-mobile_1ahf46",
  "ak-icon-lock": "_ak-icon-lock_1ahf46",
  "ak-icon-circle": "_ak-icon-circle_1ahf46",
  "ak-icon-timer": "_ak-icon-timer_1ahf46",
  "ak-icon-drag-handle": "_ak-icon-drag-handle_1ahf46",
  "ak-icon-remove-circle": "_ak-icon-remove-circle_1ahf46",
  "ak-icon-text-snippet": "_ak-icon-text-snippet_1ahf46",
  "ak-icon-schedule": "_ak-icon-schedule_1ahf46",
  "ak-icon-key": "_ak-icon-key_1ahf46",
  "ak-icon-arrow-forward": "_ak-icon-arrow-forward_1ahf46",
  "ak-icon-calendar-month": "_ak-icon-calendar-month_1ahf46",
  "ak-icon-content-paste": "_ak-icon-content-paste_1ahf46",
  "ak-icon-email": "_ak-icon-email_1ahf46",
  "ak-icon-send": "_ak-icon-send_1ahf46",
  "ak-icon-chevron-left": "_ak-icon-chevron-left_1ahf46",
  "ak-icon-chevron-right": "_ak-icon-chevron-right_1ahf46",
  "ak-icon-double-arrow-left": "_ak-icon-double-arrow-left_1ahf46",
  "ak-icon-double-arrow-right": "_ak-icon-double-arrow-right_1ahf46",
  "ak-icon-apps": "_ak-icon-apps_1ahf46",
  "ak-icon-compare": "_ak-icon-compare_1ahf46",
  "ak-icon-shape-line": "_ak-icon-shape-line_1ahf46",
  "ak-icon-check": "_ak-icon-check_1ahf46",
  "ak-icon-article": "_ak-icon-article_1ahf46",
  "ak-icon-link": "_ak-icon-link_1ahf46",
  "ak-icon-show-chart": "_ak-icon-show-chart_1ahf46",
  "ak-icon-downloading": "_ak-icon-downloading_1ahf46",
  "ak-icon-download-done": "_ak-icon-download-done_1ahf46",
  "ak-icon-desktop-windows": "_ak-icon-desktop-windows_1ahf46",
  "ak-icon-visibility": "_ak-icon-visibility_1ahf46",
  "ak-icon-visibility-off": "_ak-icon-visibility-off_1ahf46",
  "ak-icon-lock-open": "_ak-icon-lock-open_1ahf46",
  "ak-icon-chat-bubble": "_ak-icon-chat-bubble_1ahf46",
  "ak-icon-keyboard-tab": "_ak-icon-keyboard-tab_1ahf46",
  "ak-icon-date-range": "_ak-icon-date-range_1ahf46",
  "ak-icon-filter-list": "_ak-icon-filter-list_1ahf46",
  "ak-icon-sort": "_ak-icon-sort_1ahf46",
  "ak-icon-tungsten": "_ak-icon-tungsten_1ahf46",
  "ak-icon-drive-file-rename-outline": "_ak-icon-drive-file-rename-outline_1ahf46",
  "ak-icon-event-note": "_ak-icon-event-note_1ahf46",
  "ak-icon-open-in-full": "_ak-icon-open-in-full_1ahf46",
  "ak-icon-bug-report": "_ak-icon-bug-report_1ahf46",
  "ak-icon-expand-more": "_ak-icon-expand-more_1ahf46",
  "ak-icon-verified-user": "_ak-icon-verified-user_1ahf46",
  "ak-icon-auto-fix-high": "_ak-icon-auto-fix-high_1ahf46",
  "ak-icon-insert-drive-file": "_ak-icon-insert-drive-file_1ahf46",
  "ak-icon-do-not-disturb-on": "_ak-icon-do-not-disturb-on_1ahf46",
  "ak-icon-stop-circle": "_ak-icon-stop-circle_1ahf46",
  "ak-icon-location-searching": "_ak-icon-location-searching_1ahf46",
  "ak-icon-auto-graph": "_ak-icon-auto-graph_1ahf46",
  "ak-icon-north-east": "_ak-icon-north-east_1ahf46",
  "ak-icon-schedule-send": "_ak-icon-schedule-send_1ahf46",
  "ak-icon-add-box": "_ak-icon-add-box_1ahf46"
};
