import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkCheckbox\n  @onChange={{this.handleChange}}\n  @checked={{@selected}}\n  @disabled={{@loading}}\n/>", {"contents":"<AkCheckbox\n  @onChange={{this.handleChange}}\n  @checked={{@selected}}\n  @disabled={{@loading}}\n/>","moduleName":"irene/components/storeknox/table-columns/checkbox-header/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/table-columns/checkbox-header/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface StoreknoxDiscoverTableColumnsCheckboxHeaderSignature {
  Args: {
    loading: boolean;
    selected: boolean;
  };
}

export default class StoreknoxDiscoverTableColumnsCheckboxHeaderComponent extends Component<StoreknoxDiscoverTableColumnsCheckboxHeaderSignature> {
  @action handleChange() {
    // Will require when multiple is in scope
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::TableColumns::CheckboxHeader': typeof StoreknoxDiscoverTableColumnsCheckboxHeaderComponent;
  }
}
