import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkBreadcrumbs::Container class='pt-2'>\n  {{#each this.breadcrumbItems as |item|}}\n    <AkBreadcrumbs::Item\n      @route={{item.route}}\n      @model={{item.model}}\n      @linkTitle={{item.linkTitle}}\n    />\n  {{/each}}\n</AkBreadcrumbs::Container>\n\n<AkStack\n  @direction='column'\n  @spacing='0.5'\n  local-class='header-storeknox-review-logs-page'\n>\n  <AkTypography @variant='subtitle1'>\n    {{t 'storeknox.reviewLogs'}}\n  </AkTypography>\n\n  <AkTypography\n    @variant='body2'\n    local-class='description-storeknox-review-logs-page'\n  >\n    Lorem ipsum dolor sit amet consectetur. At suspendisse et orci risus non\n    sed. Mauris dui tincidunt\n  </AkTypography>\n</AkStack>\n\n<Storeknox::Inventory::PendingReview::Table\n  @columns={{this.columns}}\n  @data={{this.reviewLogApps}}\n  @router='authenticated.storeknox.discover.review-logs'\n/>", {"contents":"<AkBreadcrumbs::Container class='pt-2'>\n  {{#each this.breadcrumbItems as |item|}}\n    <AkBreadcrumbs::Item\n      @route={{item.route}}\n      @model={{item.model}}\n      @linkTitle={{item.linkTitle}}\n    />\n  {{/each}}\n</AkBreadcrumbs::Container>\n\n<AkStack\n  @direction='column'\n  @spacing='0.5'\n  local-class='header-storeknox-review-logs-page'\n>\n  <AkTypography @variant='subtitle1'>\n    {{t 'storeknox.reviewLogs'}}\n  </AkTypography>\n\n  <AkTypography\n    @variant='body2'\n    local-class='description-storeknox-review-logs-page'\n  >\n    Lorem ipsum dolor sit amet consectetur. At suspendisse et orci risus non\n    sed. Mauris dui tincidunt\n  </AkTypography>\n</AkStack>\n\n<Storeknox::Inventory::PendingReview::Table\n  @columns={{this.columns}}\n  @data={{this.reviewLogApps}}\n  @router='authenticated.storeknox.discover.review-logs'\n/>","moduleName":"irene/components/storeknox/review-logs/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/review-logs/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class StoreknoxReviewLogsComponent extends Component {
  @service declare intl: IntlService;

  get columns() {
    return [
      {
        headerComponent: 'storeknox/table-columns/store-header',
        cellComponent: 'storeknox/table-columns/store',
        minWidth: 50,
        width: 50,
        textAlign: 'center',
      },
      {
        name: this.intl.t('application'),
        cellComponent: 'storeknox/table-columns/application',
        width: 200,
      },
      {
        headerComponent:
          'storeknox/inventory/pending-review/table/found-by-header',
        cellComponent: 'storeknox/inventory/pending-review/table/found-by',
      },
      {
        name: this.intl.t('status'),
        cellComponent: 'storeknox/inventory/pending-review/table/status',
        width: 80,
      },
    ];
  }

  get reviewLogApps() {
    return [
      {
        isAndroid: true,
        iconUrl:
          'https://appknox-production-public.s3.amazonaws.com/908e507e-1148-4f4d-9939-6dba3d645abc.png',
        name: 'Shell Asia',
        packageName: 'com.shellasia.android',
        foundBy: 'Auto Discovery',
        status: 'approved',
        actionTakenBy: 'sujith',
      },
      {
        isIos: true,
        iconUrl:
          'https://appknox-production-public.s3.amazonaws.com/908e507e-1148-4f4d-9939-6dba3d645abc.png',
        name: 'Shell Recharge India',
        packageName: 'com.shellrecharge.india',
        foundBy: 'Manual Discovery',
        status: 'approved',
        actionTakenBy: 'smit',
      },
      {
        isAndroid: true,
        iconUrl:
          'https://appknox-production-public.s3.amazonaws.com/908e507e-1148-4f4d-9939-6dba3d645abc.png',
        name: 'Shell Mobility Site Manager',
        packageName: 'com.shellmobility.ios',
        foundBy: 'Auto Discovery',
        status: 'rejected',
        actionTakenBy: 'sujith',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::ReviewLogs': typeof StoreknoxReviewLogsComponent;
  }
}
