import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @alignItems='center' local-class='empty-container'>\n\n  <AkSvg::StoreknoxSearchApps />\n\n  <AkTypography @variant='h5' @gutterBottom={{true}} local-class='header'>\n    {{t 'storeknox.searchForApps'}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text'>\n    {{t 'storeknox.searchForAppsDescription'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @direction='column' @alignItems='center' local-class='empty-container'>\n\n  <AkSvg::StoreknoxSearchApps />\n\n  <AkTypography @variant='h5' @gutterBottom={{true}} local-class='header'>\n    {{t 'storeknox.searchForApps'}}\n  </AkTypography>\n\n  <AkTypography local-class='body-text'>\n    {{t 'storeknox.searchForAppsDescription'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/storeknox/discover/results/empty/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/discover/results/empty/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxDiscoverResultsEmptyComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::Results::Empty': typeof StoreknoxDiscoverResultsEmptyComponent;
  }
}
